<template>
  <div>
    <v-card>
      <v-card-title>
        Fitness Assessments Library
        <v-spacer />

        <v-btn v-if="selected.length > 0" class="mr-2 mb-2" color="secondary" @click="showBulkStatusDialog">
          UPDATE SELECTED ({{ selected.length }})
        </v-btn>

        <v-btn class="mb-2" color="primary" dark @click="showEditDialog(blankFitnessAssessment)"> CREATE </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" offset-md="8">
            <v-text-field
              v-model="dtSearchQuery"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        v-model="selected"
        :headers="dtHeaders"
        :items="fitnessAssessments"
        :search="dtSearchQuery"
        :expanded.sync="dtExpanded"
        :loading="isLoading"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [15, 30, 45] }"
        show-select
        single-expand
        show-expand
        item-key="id"
      >
        <template v-slot:item.sessionType="{ item }">
          <v-chip>
            {{ item.sessionType }}
          </v-chip>
        </template>

        <template v-slot:item.category="{ item }">
          <v-chip>
            {{ item.category }}
          </v-chip>
        </template>

        <template v-slot:item.level="{ item }">
          <v-chip>
            {{ item.level }}
          </v-chip>
        </template>

        <template v-slot:item.frequency="{ item }">
          <v-chip>
            {{ item.frequency }}
          </v-chip>
        </template>

        <template v-slot:item.measurement="{ item }">
          <v-chip>
            {{ item.measurement }}
          </v-chip>
        </template>

        <template v-slot:item.practiceType="{ item }">
          <v-chip>
            {{ item.practiceType }}
          </v-chip>
        </template>

        <template v-slot:item.goal="{ item }">
          <v-icon v-if="item.goal" color="success">
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon v-else color="error">
            {{ icons.mdiClose }}
          </v-icon>
        </template>

        <template v-slot:item.exercisePriority="{ item }">
          <v-chip>
            {{ item.exercisePriority }}
          </v-chip>
        </template>

        <template v-slot:item.scoringDescriptions="{ item }">
          <v-chip>
            {{ item.scoringDescriptions.length }}
          </v-chip>
        </template>

        <template v-slot:item.tips="{ item }">
          <v-chip>
            {{ item.tips.length }}
          </v-chip>
        </template>

        <template v-slot:item.exercise="{ item }">
          <v-icon v-if="item.exercise" color="success">
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon v-else color="error">
            {{ icons.mdiClose }}
          </v-icon>
        </template>

        <template v-slot:item.active="{ item }">
          <v-chip :color="item.active ? 'success' : 'error'" dark>
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="showEditDialog(item)">
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon @click="showDeleteConfirmationDialog(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <fitness-assessment-details :item="item" />
          </td>
        </template>
      </v-data-table>

      <delete-dialog
        v-model="isDeleteDialogVisible"
        :item="fitnessAssessment"
        :is-loading="isLoading"
        @cancel="onDeleteCanceled"
        @delete="onDeleteConfirmed"
      />

      <fitness-assessment-edit-dialog
        v-if="isEditDialogVisible"
        v-model="isEditDialogVisible"
        :item="fitnessAssessment"
        :is-loading="isLoading"
        @cancel="onEditCanceled"
        @save="onEditSave"
      />

      <v-dialog v-model="isBulkStatusDialogVisible" max-width="500">
        <v-card>
          <v-card-title>Update Status for Selected Items</v-card-title>
          <v-card-text>
            <p>You are about to update the status of {{ selected.length }} selected fitness assessment(s).</p>
            <v-select
              v-model="bulkStatusValue"
              :items="[
                { text: 'Mark as Active', value: true },
                { text: 'Mark as Inactive', value: false },
              ]"
              label="Select Status"
              outlined
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="isBulkStatusDialogVisible = false">Cancel</v-btn>
            <v-btn color="primary" :loading="isBulkUpdating" @click="updateBulkStatus"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mdiCheck, mdiClose, mdiDeleteOutline, mdiPencilOutline, mdiMagnify } from '@mdi/js'
import { onMounted, ref } from 'vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import FitnessAssessmentDetails from '@/views/fitnessAssessments/partials/FitnessAssessmentDetails.vue'
import FitnessAssessmentEditDialog from '@/views/fitnessAssessments/partials/FitnessAssessmentEditDialog.vue'
import useFitnessAssessments from '@/composables/api/assessments/fitness'
import useUniqueKey from '@/composables/uniqueKey'

export default {
  components: {
    DeleteDialog,
    FitnessAssessmentDetails,
    FitnessAssessmentEditDialog,
  },

  setup() {
    const {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,
      blankFitnessAssessment,
      fitnessAssessment,
      fitnessAssessments,
      isLoading,
      createFitnessAssessment,
      deleteFitnessAssessment,
      fetchFitnessAssessments,
      updateFitnessAssessment,
      updateManyFitnessAssessments,
    } = useFitnessAssessments()

    const { uniqueKey } = useUniqueKey()

    const isDeleteDialogVisible = ref(false)
    const isEditDialogVisible = ref(false)
    const selected = ref([])
    const isBulkStatusDialogVisible = ref(false)
    const bulkStatusValue = ref(true)
    const isBulkUpdating = ref(false)

    onMounted(async () => {
      await fetchFitnessAssessments()
    })

    const showDeleteConfirmationDialog = fitnessAssessmentItem => {
      const item = structuredClone(fitnessAssessmentItem)

      if (item.exercise && item.exercise.uniqueKey) {
        item.exerciseUniqueKey = item.exercise.uniqueKey
      }

      fitnessAssessment.value = item
      isDeleteDialogVisible.value = true
    }

    const onDeleteCanceled = () => {
      fitnessAssessment.value = structuredClone(blankFitnessAssessment)
      isDeleteDialogVisible.value = false
    }

    const onDeleteConfirmed = async fitnessAssessmentItem => {
      await deleteFitnessAssessment(fitnessAssessmentItem)
      await fetchFitnessAssessments()

      isDeleteDialogVisible.value = false
    }

    const showEditDialog = fitnessAssessmentItem => {
      const item = structuredClone(fitnessAssessmentItem)

      if (!item.uniqueKey) {
        item.uniqueKey = uniqueKey()
      }

      fitnessAssessment.value = item
      isEditDialogVisible.value = true
    }

    const onEditCanceled = () => {
      fitnessAssessment.value = structuredClone(blankFitnessAssessment)
      isEditDialogVisible.value = false
    }

    const onEditSave = async fitnessAssessmentItem => {
      try {
        // eslint-disable-next-line no-param-reassign
        fitnessAssessmentItem.exerciseUniqueKey = fitnessAssessmentItem.exercise.uniqueKey

        if (!fitnessAssessmentItem.id) {
          await createFitnessAssessment(fitnessAssessmentItem)
          await fetchFitnessAssessments()
        } else {
          await updateFitnessAssessment(fitnessAssessmentItem)
          await fetchFitnessAssessments()
        }

        isEditDialogVisible.value = false
      } catch (error) {
        console.error(error)
      }
    }

    const showBulkStatusDialog = () => {
      isBulkStatusDialogVisible.value = true
    }

    const updateBulkStatus = async () => {
      if (selected.value.length === 0) return

      isBulkUpdating.value = true

      try {
        await updateManyFitnessAssessments(
          'FITNESS_ASSESSMENT',
          'active',
          bulkStatusValue.value,
          selected.value.map(item => item.id),
        )

        await fetchFitnessAssessments()

        selected.value = []
        isBulkStatusDialogVisible.value = false
      } catch (error) {
        console.error(error)
      } finally {
        isBulkUpdating.value = false
      }
    }

    return {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,

      blankFitnessAssessment,
      fitnessAssessments,
      fitnessAssessment,

      isDeleteDialogVisible,
      showDeleteConfirmationDialog,
      onDeleteCanceled,
      onDeleteConfirmed,

      isEditDialogVisible,
      showEditDialog,
      onEditCanceled,
      onEditSave,

      isLoading,

      selected,
      isBulkStatusDialogVisible,
      bulkStatusValue,
      isBulkUpdating,
      showBulkStatusDialog,
      updateBulkStatus,

      icons: {
        mdiCheck,
        mdiClose,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiMagnify,
      },
    }
  },
}
</script>
